import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

const NotFoundPage = () => (
  <Layout>
    <SEO title="Page not found" />
    <p>The requested page could not be found.</p>
  </Layout>
)

export default NotFoundPage
